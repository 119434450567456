import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import leagueReducer from '../reducers/league';
import scheduleDisplayReducer from '../reducers/scheduleDisplay'; 
import roundDisplayReducer from '../reducers/roundDisplay'; 

export default () => {
    const store = createStore(
        combineReducers({
            league: leagueReducer,
            scheduleDisplay: scheduleDisplayReducer,
            roundDisplay: roundDisplayReducer
        }),
        {},
        applyMiddleware(ReduxThunk)
    );

    return store;
}