import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import { getLeague } from '../actions/league';
import defaultImage from '../assets/images/field-default.png';

class TeamsPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleManagerModal = this.toggleManagerModal.bind(this);

        this.state = {
            managerModalOpen: false,
            currentTeamManager: {}
        }
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
    }

    toggleManagerModal(open) {
        this.setState(() => ({
            managerModalOpen: open
        }));
    }

    viewTeamManager(theTeam) {
        this.setState(() => ({
            managerModalOpen: true,
            currentTeamManager: theTeam
        }));
    }

    viewTeamRoster(theTeam) {
        this.props.history.push('/'+this.props.info.sitename+'/teams/'+theTeam.id+'/roster/');
    }

    viewTeamSchedule(theTeam) {
        this.props.history.push('/'+this.props.info.sitename+'/teams/'+theTeam.id+'/schedule/');
    }

    viewTeamPlayoffSchedule(theTeam) {
        this.props.history.push('/'+this.props.info.sitename+'/teams/'+theTeam.id+'/playoffs/schedule/');
    }

    printDivisionName(division_id) {
        let divisionName = "";

        this.props.divisions.forEach((theDivision) => {
            if (theDivision.id == division_id) {
                divisionName = theDivision.name;
            }
        });

        return divisionName;
    }

    renderTeamManager() {
        return (
            <Modal
                aria-labelledby="view-team-manager"
                aria-describedby="view-team-manager"
                open={this.state.managerModalOpen}
                onClose={() => this.toggleManagerModal(false)}
                className="modal_admin"
            >
                <div className="modal-member">
                    <Typography variant="h4" className="modal-title">
                        {this.state.currentTeamManager.name}
                    </Typography>
                    <Typography variant="h5" className="modal-description">
                        <strong>Manager:</strong> {this.state.currentTeamManager.manager_name}
                    </Typography>
                    <Typography variant="h5" className="modal-description">
                        <strong>Phone:</strong> {this.state.currentTeamManager.manager_phone}
                    </Typography>
                </div>
            </Modal>
        );
    }
    
    renderTeams() {
        if (this.props.teams.length > 0) {
            return this.props.teams.map((theTeam) => {
                return (
                    <Grid item md={4} sm={6} xs={12} className="grid-container" key={"team-"+theTeam.id}>
                        <Card className="card">
                            <CardActionArea>
                                <CardMedia
                                className="card-image"
                                image={defaultImage}
                                title={theTeam.name}
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {theTeam.name}
                                </Typography>
                                {(theTeam.division_id != 0) && (
                                    <Typography component="p">
                                        <strong>Division:</strong> {this.printDivisionName(theTeam.division_id)}
                                    </Typography>
                                )}
                                {(theTeam.manager_name != "") && (
                                    <Typography component="p">
                                        <strong>Manager:</strong> {theTeam.manager_name}
                                    </Typography>
                                )}
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" onClick={() => { this.viewTeamManager(theTeam) }}>
                                    Contact
                                </Button>
                                <Button size="small" color="primary" onClick={() => { this.viewTeamRoster(theTeam) }}>
                                    Roster
                                </Button>
                                <Button size="small" color="primary" onClick={() => { this.viewTeamSchedule(theTeam) }}>
                                    Schedule
                                </Button>
                                <Button size="small" color="primary" onClick={() => { this.viewTeamPlayoffSchedule(theTeam) }}>
                                    Playoff Schedule
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            });
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No teams have been entered for the league yet.
                    </Typography>
                </Grid>
            );
        }
    }

    render() {

        return (
            <div className="container">
                <Grid container>
                    <Grid item xs={12} className="grid-container">
                        <Typography variant="h4" className="league-title" gutterBottom>
                            {this.props.info.name}
                        </Typography>
                        <Typography variant="h5" className="page-title" gutterBottom>
                            Teams
                        </Typography>
                    </Grid>

                    {this.renderTeams()}
                    {this.renderTeamManager()}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        divisions: state.league.divisions,
        teams: state.league.teams
    };
};

export default connect(mapStateToProps)(TeamsPage);