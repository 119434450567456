import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import { getLeague } from '../actions/league';
import { getPrettyTime, getFormattedGameTime } from '../utils/dateUtil';
import defaultImage from '../assets/images/field-default.png';

class TeamSchedulePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTeam: {}
        }
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
        
    }

    printTeamName(team_id) {

        let teamName = "";

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }
    
    printScore(theGame, isHome) {

        if (theGame.is_played) {
            if (isHome) {
                return theGame.home_score;
            }
            else {
                return theGame.away_score;
            }
        }
        else {
            return '';
        }
    }

    printGameDate(gameTime) {
        const theGameTime = new Date(gameTime);
        return getPrettyTime(theGameTime, true, false);
    }

    printGameTime(gameTime) {
        gameTime = gameTime.replace("Z", "-03:00");
	    const theGameTime = new Date(gameTime);
        return getFormattedGameTime(theGameTime);
    }

    printField(field_id) {

        let fieldName = "";

        this.props.fields.forEach((theField) => {
            if (theField.id == field_id) {
                fieldName = theField.name;
            }
        });

        return fieldName;
    }

    renderSchedule() {
        if (this.props.currentTeam.schedule.length > 0) {
            return (
                <Grid item xs={12} className="grid-container-nopad-vert overflow-horz">
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Away Team</TableCell>
                            <TableCell align="center" className="table-item-score"></TableCell>
                            <TableCell align="center" className="table-item-at"></TableCell>
                            <TableCell align="center" className="table-item-score"></TableCell>
                            <TableCell align="center">Home Team</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Field</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.currentTeam.schedule.map((theGame) => (
                            <TableRow key={theGame.id}>
                                <TableCell align="center">{this.printTeamName(theGame.away_team_id)}</TableCell>
                                <TableCell align="center" className="table-item-score">{this.printScore(theGame, false)}{(theGame.is_away_forfeit == true) && " (F)"}</TableCell>
                                <TableCell align="center" className="table-item-at">@</TableCell>
                                <TableCell align="center" className="table-item-score">{this.printScore(theGame, true)}{(theGame.is_home_forfeit == true) && " (F)"}</TableCell>
                                <TableCell align="center">{this.printTeamName(theGame.home_team_id)}</TableCell>
                                <TableCell>{this.printGameDate(theGame.game_time)}</TableCell>
                                <TableCell>{this.printGameTime(theGame.game_time)}</TableCell>
                                <TableCell>{this.printField(theGame.field_id)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Grid>
            );
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No schedule has been entered yet.
                    </Typography>
                </Grid>
            );
        }
    }

    render() {
        if (this.props.currentTeam) {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h4" className="league-title"  gutterBottom>
                                {this.props.currentTeam.name}
                            </Typography>
                            <Typography variant="h5" className="page-title" gutterBottom>
                                Schedule
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="grid-container-nopad-vert">
                            <Button size="small" className="button-schedule-left" color="primary" onClick={() => { this.props.history.push('/'+this.props.info.sitename+'/teams/'); }}>
                                Back to Teams
                            </Button>
                            <Button size="small" className="button-schedule-right" color="primary" onClick={() => { this.props.history.push('/'+this.props.info.sitename+'/teams/'+this.props.match.params.tid+'/roster/'); }}>
                                Roster
                            </Button>
                        </Grid>
    
                        {this.renderSchedule()}
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h3" gutterBottom>
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        divisions: state.league.divisions,
        fields: state.league.fields,
        teams: state.league.teams,
        currentTeam: state.league.teams.find((theTeam) => { return theTeam.id == props.match.params.tid })
    };
};

export default connect(mapStateToProps)(TeamSchedulePage);
