export const roundDisplayUpdate = (round_id) => {

    return {
        type: 'ROUND_DISPLAY_UPDATE',
        round_id
    };
};

export const roundDisplayReset = () => {
    
    return {
        type: 'ROUND_DISPLAY_RESET',
    };
}