const scheduleDisplayDefaultState = {
    displayType: 'upcoming',
};

export default (state = scheduleDisplayDefaultState, action) => {
    switch (action.type) {

        case 'SCHEDULE_DISPLAY_UPDATE':
            return {
                ...state,
                displayType: action.displayType
            }

        case 'SCHEDULE_DISPLAY_RESET':
            return scheduleDisplayDefaultState;

        default:
            return state;
    }
};