import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import { getLeague } from '../actions/league';

class TeamRosterPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleManagerModal = this.toggleManagerModal.bind(this);

        this.state = {
            currentTeam: {}
        }
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }

    }

    componentDidUpdate() {
        
    }

    toggleManagerModal(open) {
        this.setState(() => ({
            managerModalOpen: open
        }));
    }

    viewTeamManager(theTeam) {
        this.setState(() => ({
            managerModalOpen: true,
            currentTeamManager: theTeam
        }));
    }

    printDivisionName(division_id) {
        let divisionName = "";

        this.props.divisions.forEach((theDivision) => {
            if (theDivision.id == division_id) {
                divisionName = theDivision.name;
            }
        });

        return divisionName;
    }
    
    renderRoster() {
        if (this.props.currentTeam.roster.length > 0) {
            return (
                <Table className="display-table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Player</TableCell>
                        <TableCell align="right">Position</TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.props.currentTeam.roster.map((thePlayer) => (
                        <TableRow key={thePlayer.id}>
                            <TableCell component="th" scope="row">
                                {thePlayer.name}
                            </TableCell>
                            <TableCell align="right">{thePlayer.position}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            );
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No roster players have been entered yet.
                    </Typography>
                </Grid>
            );
        }
    }

    render() {
        if (this.props.currentTeam) {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h4" className="league-title" gutterBottom>
                                {this.props.currentTeam.name}
                            </Typography>
                            <Typography variant="h5" className="page-title" gutterBottom>
                                Roster
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="grid-container-nopad-vert">
                            <Button size="small" className="button-schedule-left" color="primary" onClick={() => { this.props.history.push('/'+this.props.info.sitename+'/teams/'); }}>
                                Back to Teams
                            </Button>
                            <Button size="small" className="button-schedule-right" color="primary" onClick={() => { this.props.history.push('/'+this.props.info.sitename+'/teams/'+this.props.match.params.tid+'/schedule/'); }}>
                                Schedule
                            </Button>
                        </Grid>
    
                        {this.renderRoster()}
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h3" gutterBottom>
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        divisions: state.league.divisions,
        currentTeam: state.league.teams.find((theTeam) => { return theTeam.id == props.match.params.tid })
    };
};

export default connect(mapStateToProps)(TeamRosterPage);