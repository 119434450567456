import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { getLeague } from '../actions/league';

class RulesPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }
    
    renderRulesAndSubrules() {
        if (this.props.rules.length > 0) {

            // print each individual rule
            return this.props.rules.map((theRule) => {
                return (
                    <Grid item xs={12} className="grid-container" key={"main-"+theRule.id}>
                        <div className="window-rule">
                            {(theRule.title != "") && (
                                <Typography variant="h5" gutterBottom>
                                    {theRule.title}
                                </Typography>
                            )}
                            <Typography variant="subtitle1" gutterBottom>
                                {theRule.rule}
                            </Typography>
                        </div>

                        {theRule.subRules.map((theSubRule) => {
                            return (
                                <div className="window-subrule" key={"sub-"+theSubRule.id}>
                                    <Typography variant="h6" gutterBottom>
                                        {theSubRule.title}
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {theSubRule.rule}
                                    </Typography>
                                </div>
                            );
                        })}
                    </Grid>
                );
            });
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No rules have been entered for the league yet.
                    </Typography>
                </Grid>
            );
        }
    }

    render() {

        return (
            <div className="container">
                <Grid container>
                    <Grid item xs={12} className="grid-container">
                        <Typography variant="h4" className="league-title" gutterBottom>
                            {this.props.info.name}
                        </Typography>
                        <Typography variant="h5" className="page-title" gutterBottom>
                            Rules
                        </Typography>
                    </Grid>

                    {this.renderRulesAndSubrules()}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        rules: state.league.rules
    };
};

export default connect(mapStateToProps)(RulesPage);