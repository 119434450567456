import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getLeague } from '../actions/league';
import { getPrettyTime, printMonthShort, getFormattedGameTime } from '../utils/dateUtil';
import { scheduleDisplayUpdate } from '../actions/scheduleDisplay';
import defaultImage from '../assets/images/field-default.png';

class SchedulePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            monthDisplay: new Date(),
            currentTeam: {}
        }
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.month && this.props.match.params.month.match(/^\d+$/) && (parseInt(this.props.match.params.month) - 1) != this.state.monthDisplay.getMonth()) {
            
            const newDate = this.state.monthDisplay;
            newDate.setMonth(parseInt(this.props.match.params.month) - 1); 

            this.setState(() => ({
                monthDisplay: newDate
            }))
            
        }
    }

    updateScheduleType(theType) {
        this.props.dispatch(scheduleDisplayUpdate(theType));
    }

    printTeamName(team_id) {

        let teamName = "";

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }
    
    printScore(theGame, isHome) {

        if (theGame.is_played) {
            if (isHome) {
                return theGame.home_score;
            }
            else {
                return theGame.away_score;
            }
        }
        else {
            return '';
        }
    }

    printGameDate(gameTime) {
        const theGameTime = new Date(gameTime);
        return getPrettyTime(theGameTime, true, false);
    }

    printGameTime(gameTime) {
	gameTime = gameTime.replace("Z", "-03:00");
        const theGameTime = new Date(gameTime);
        return getFormattedGameTime(theGameTime);
    }

    printField(field_id) {

        let fieldName = "";

        this.props.fields.forEach((theField) => {
            if (theField.id == field_id) {
                fieldName = theField.name;
            }
        });

        return fieldName;
    }

    renderSchedule() {
        if (this.props.schedule.length > 0) {
            return (
                <Grid item xs={12} className="grid-container-nopad-vert overflow-horz">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Away Team</TableCell>
                                <TableCell align="center" className="table-item-score"></TableCell>
                                <TableCell align="center" className="table-item-at"></TableCell>
                                <TableCell align="center" className="table-item-score"></TableCell>
                                <TableCell align="center">Home Team</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Field</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.schedule.map((theGame) => (
                            <TableRow key={theGame.id}>
                                <TableCell align="center"><Link to={"/"+this.props.info.sitename+"/teams/"+theGame.away_team_id+"/playoffs/schedule/"}>{this.printTeamName(theGame.away_team_id)}</Link></TableCell>
                                <TableCell align="center" className="table-item-score">{this.printScore(theGame, false)}{(theGame.is_away_forfeit == true) && " (F)"}</TableCell>
                                <TableCell align="center" className="table-item-at">@</TableCell>
                                <TableCell align="center" className="table-item-score">{this.printScore(theGame, true)}{(theGame.is_home_forfeit == true) && " (F)"}</TableCell>
                                <TableCell align="center"><Link to={"/"+this.props.info.sitename+"/teams/"+theGame.home_team_id+"/playoffs/schedule/"}>{this.printTeamName(theGame.home_team_id)}</Link></TableCell>
                                <TableCell>{this.printGameDate(theGame.game_time)}</TableCell>
                                <TableCell>{this.printGameTime(theGame.game_time)}</TableCell>
                                <TableCell>{this.printField(theGame.field_id)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Grid>
            );
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No schedule has been entered yet.
                    </Typography>
                </Grid>
            );
        }
    }

    viewPrevMonth() {
        this.props.history.push('/'+this.props.info.sitename+'/playoffs/schedule/'+(this.state.monthDisplay.getMonth())+'/');
    }

    viewNextMonth() {
        this.props.history.push('/'+this.props.info.sitename+'/playoffs/schedule/'+(this.state.monthDisplay.getMonth()+2)+'/');
    }

    render() {
        if (this.props.schedule) {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h4" className="league-title" gutterBottom>
                                {this.props.info.name}
                            </Typography>
                            <Typography variant="h5" className="page-title" gutterBottom>
                                Playoff Schedule
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                {printMonthShort(this.state.monthDisplay.getMonth())+" "+this.state.monthDisplay.getFullYear()}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="grid-container-pad-bottom">
                            <Button size="small" className={(this.props.displayType == "upcoming") ? "button-schedule-left button-schedule-selected" : "button-schedule-left"} color="primary" onClick={() => { this.updateScheduleType('upcoming') }}>
                                Upcoming Games
                            </Button>
                            <Button size="small" className={(this.props.displayType == "played") ? "button-schedule-left button-schedule-selected" : "button-schedule-left"} color="primary" onClick={() => { this.updateScheduleType('played') }}>
                                Played Games
                            </Button>
                            <Button size="small" className={(this.props.displayType == "all") ? "button-schedule-left button-schedule-selected" : "button-schedule-left"} color="primary" onClick={() => { this.updateScheduleType('all') }}>
                                All Games
                            </Button>
                        </Grid>

                        <Grid item xs={12} className="grid-container-nopad-vert">
                            <Button size="small" className="button-schedule-left" color="primary" onClick={() => { this.viewPrevMonth() }}>
                                Prev Month
                            </Button>
                            <Button size="small" className="button-schedule-right" color="primary" onClick={() => { this.viewNextMonth() }}>
                                Next Month
                            </Button>
                        </Grid>
    
                        {this.renderSchedule()}
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h3" gutterBottom>
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        schedule: state.league.playoffSchedule.filter((theGame) => {
            const gt = new Date(theGame.game_time);
            const gameMonth = new Date();
            let isValid = true;

            if (props.match.params.month && props.match.params.month.match(/^\d+$/)) {
                gameMonth.setMonth(parseInt(props.match.params.month) - 1); 
            }

            if (state.scheduleDisplay.displayType != 'all') {
                if (theGame.is_played && state.scheduleDisplay.displayType == "upcoming") {
                    isValid = false;
                }
                else if (!theGame.is_played && state.scheduleDisplay.displayType == "played") {
                    isValid = false;
                }
            }

            return gt.getMonth() == gameMonth.getMonth() && isValid;
        }),
        divisions: state.league.divisions,
        fields: state.league.fields,
        teams: state.league.teams,
        displayType: state.scheduleDisplay.displayType
    };
};

export default connect(mapStateToProps)(SchedulePage);
