import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getLeague } from '../actions/league';

class StandingsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            if (this.props.match.params.id) {
                this.props.dispatch(getLeague(this.props.match.params.id));
            }
            else {
                this.props.dispatch(getLeague(this.props.sitename));
            }
        }
    }

    componentDidUpdate() {
    }

    printDivisionName(div_id) {

        let divName = "";

        this.props.divisions.forEach((theDiv) => {
            if (theDiv.id == div_id) {
                divName = theDiv.name;
            }
        });

        return divName;
    }

    printTeamName(team_id) {

        let teamName = "";

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }

    renderStandingsTable(division_id, theStandings) {
        return (
            <Grid item xs={12} className="grid-container-nopad-vert overflow-horz" key={division_id}>

                {division_id > 0 && (
                    <Typography variant="h5" gutterBottom>
                        {this.printDivisionName(division_id)}
                    </Typography>
                )}

                <Table className="standings-table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Team</TableCell>
                            <TableCell align="center">Games Played</TableCell>
                            <TableCell align="center">Points</TableCell>
                            <TableCell align="center">W</TableCell>
                            <TableCell align="center">L</TableCell>
                            <TableCell align="center">T</TableCell>
                            <TableCell align="center">RF</TableCell>
                            <TableCell align="center">RA</TableCell>
                            <TableCell align="center">Forfeits</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {theStandings.map((theStandingsTeam, index) => {
                        if (theStandingsTeam.division_id == division_id) {
                            return (
                                <TableRow key={theStandingsTeam.team_id}>
                                    <TableCell align="center">{this.printTeamName(theStandingsTeam.team_id)}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.games_played}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.points}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.wins}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.losses}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.ties}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.runs_for}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.runs_against}</TableCell>
                                    <TableCell align="center">{theStandingsTeam.forfeits}</TableCell>
                                </TableRow>
                            );
                        }
                    })}
                    </TableBody>
                </Table>
            </Grid>
        );
    }
    
    renderStandings() {
        if (this.props.divisions.length <= 0) {
            return this.renderStandingsTable(0, this.props.standings);
        }
        else {
            return this.props.divisions.map((theDivision) => {
                return this.renderStandingsTable(theDivision.id, this.props.standings);
            });
        }
    }

    render() {

        if (this.props.standings) {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h4" className="league-title" gutterBottom>
                                {this.props.info.name}
                            </Typography>
                            <Typography variant="h5" className="page-title" gutterBottom>
                                Standings
                            </Typography>
                        </Grid>
    
                        {this.renderStandings()}
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h3" gutterBottom>
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        sitename: state.league.sitename,
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        standings: state.league.standings,
        divisions: state.league.divisions,
        teams: state.league.teams,
    };
};

export default connect(mapStateToProps)(StandingsPage);