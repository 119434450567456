import axios from 'axios';
import { API_URL } from '../config/constants';

export const loadLeague = (info, rules, fields, standings, schedule, divisions, teams, playoffSchedule, playoffRounds ) => {

    return {
        type: 'LEAGUE_LOAD',
        info,
        rules,
        fields,
        standings,
        schedule,
        divisions,
        teams,
        playoffSchedule,
        playoffRounds
    };
};

export const getLeague = (league_id) => {
    return (dispatch) => {
        return axios.get(API_URL+'/api/league/'+league_id)
            .then(function(res) {
                if (res.data.status == "success") {
                    dispatch(loadLeague(res.data.info, res.data.rules, res.data.fields, res.data.standings, res.data.schedule, res.data.divisions, res.data.teams, res.data.playoffSchedule, res.data.playoffRounds));
                }
            })
            .catch(function(err) {
                console.log(err);
                alert("We're sorry, but there was an issue with loading the league. Please try again shortly.");
            });
    };
};
