export const scheduleDisplayUpdate = (displayType) => {

    return {
        type: 'SCHEDULE_DISPLAY_UPDATE',
        displayType
    };
};

export const scheduleDisplayReset = () => {
    
    return {
        type: 'SCHEDULE_DISPLAY_RESET',
    };
}