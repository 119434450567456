import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import { getLeague } from '../actions/league';
import defaultImage from '../assets/images/field-default.png';

class FieldsPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
    }
    
    renderFields() {
        if (this.props.fields.length > 0) {
            return this.props.fields.map((theField) => {
                return (
                    <Grid item md={4} sm={6} xs={12} className="grid-container" key={"field-"+theField.id}>
                        <Card className="card">
                            <CardActionArea>
                                <CardMedia
                                className="card-image"
                                image={defaultImage}
                                title={theField.name}
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {theField.name}
                                </Typography>
                                <Typography component="p">
                                    {theField.description}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Directions
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            });
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No fields have been entered for the league yet.
                    </Typography>
                </Grid>
            );
        }
    }

    render() {
        return (
            <div className="container">
                <Grid container>
                    <Grid item xs={12} className="grid-container">
                        <Typography variant="h4" className="league-title" gutterBottom>
                            {this.props.info.name}
                        </Typography>
                        <Typography variant="h5" className="page-title" gutterBottom>
                            Fields
                        </Typography>
                    </Grid>

                    {this.renderFields()}
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        fields: state.league.fields
    };
};

export default connect(mapStateToProps)(FieldsPage);