const roundDisplayDefaultState = {
    round_id: 0,
};

export default (state = roundDisplayDefaultState, action) => {
    switch (action.type) {

        case 'ROUND_DISPLAY_UPDATE':
            return {
                ...state,
                round_id: action.round_id
            }

        case 'ROUND_DISPLAY_RESET':
            return roundDisplayDefaultState;

        default:
            return state;
    }
};