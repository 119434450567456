import React from 'react';
import Favicon from 'react-favicon';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import RulesPage from '../components/RulesPage';
import FieldsPage from '../components/FieldsPage';
import TeamsPage from '../components/TeamsPage';
import TeamRosterPage from '../components/TeamRosterPage';
import TeamSchedulePage from '../components/TeamSchedulePage';
import PlayoffTeamSchedulePage from '../components/PlayoffTeamSchedulePage';
import SchedulePage from '../components/SchedulePage';
import PlayoffRoundsPage from '../components/PlayoffRoundsPage';
import PlayoffSchedulePage from '../components/PlayoffSchedulePage';
import StandingsPage from '../components/StandingsPage';
import NotFoundPage from '../components/NotFoundPage';
import Header from '../components/Header';

const MainRouter = (props) => {

    return (
        <BrowserRouter>
            <div>
                <Favicon url="/favicon.ico" />
                <Header />
                <Switch>
                    <Route path="/" component={StandingsPage} exact={true} />
                    <Route path="/:id/" component={StandingsPage} exact={true} />
                    <Route path="/:id/rules/" component={RulesPage} exact={true} />
                    <Route path="/:id/fields/" component={FieldsPage} exact={true} />
                    <Route path="/:id/teams/" component={TeamsPage} exact={true} />
                    <Route path="/:id/teams/:tid/roster/" component={TeamRosterPage} exact={true} />
                    <Route path="/:id/teams/:tid/schedule/" component={TeamSchedulePage} exact={true} />
                    <Route path="/:id/schedule/" component={SchedulePage} exact={true} />
                    <Route path="/:id/schedule/:month/" component={SchedulePage} exact={true} />
                    <Route path="/:id/standings/" component={StandingsPage} exact={true} />
                    <Route path="/:id/playoffs/" component={PlayoffRoundsPage} exact={true} />
                    <Route path="/:id/playoffs/schedule/" component={PlayoffSchedulePage} exact={true} />
                    <Route path="/:id/playoffs/schedule/:month/" component={PlayoffSchedulePage} exact={true} />
                    <Route path="/:id/teams/:tid/playoffs/schedule/" component={PlayoffTeamSchedulePage} exact={true} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default MainRouter;