// the default items that we can possibly filter by
const leagueDefaultState = {
    sitename: 'cbramlb',
    isLoaded: false,
    info: {},
    standings: [],
    schedule: [],
    rules: [],
    fields: [],
    divisions: [],
    teams: [],
    playoffSchedule: [],
    playoffRounds: [],
};

export default (state = leagueDefaultState, action) => {
    switch (action.type) {
        case 'LEAGUE_LOAD':
            return {
                ...state,
                isLoaded: true,
                info: action.info,
                standings: action.standings,
                schedule: action.schedule,
                rules: action.rules,
                fields: action.fields,
                divisions: action.divisions,
                teams: action.teams,
                playoffSchedule: action.playoffSchedule,
                playoffRounds: action.playoffRounds
            };
            
        case 'LEAGUE_RESET':
            return leagueDefaultState;

        default:
            return state;
    }
};