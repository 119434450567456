import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List';

import logo from '../assets/images/headerlogo.png';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.onClickLogout = this.onClickLogout.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);

        this.state = {
            menuOpen: false
        }
    }

    onClickLogout() {
        this.props.dispatch(logout());
    }

    renderDrawer() {
        return (
            <div className="full-list">
                <List>

                    <Link 
                        to={"/"+this.props.league.sitename+"/"}
                        className="nav-link-mobile"
                    >
                        Home
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/playoffs/"} className="nav-link-mobile">
                        Playoffs
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/playoffs/schedule/"} className="nav-link-mobile">
                        Playoff Schedule
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/standings/"} className="nav-link-mobile">
                        Standings
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/schedule/"} className="nav-link-mobile">
                        Schedule
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/fields/"} className="nav-link-mobile">
                        Fields
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/rules/"} className="nav-link-mobile">
                        Rules
                    </Link>

                    <Link to={"/"+this.props.league.sitename+"/teams/"} className="nav-link-mobile">
                        Teams
                    </Link>
                </List>
            </div>
        );
    }

    toggleDrawer() {
        this.setState((prevState) => ({
            menuOpen: !prevState.menuOpen,
        }));
    };

    render() {

        return (
            <div>
                <AppBar
                    position="sticky"
                    color="primary"
                >
                    <Toolbar>
                        <IconButton className={'nav-links-menu-button'} color="inherit" aria-label="Menu" onClick={this.toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                            
                        <ul className="nav-links-view">
                            <li>
                                <Link 
                                    to={"/"+this.props.league.sitename+"/"}
                                    className="nav-link"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/standings/"} className="nav-link">
                                    Standings
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/playoffs/"} className="nav-link">
                                    Playoffs
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/playoffs/schedule/"} className="nav-link">
                                    Playoff Schedule
                                </Link>
                            </li>
                            <li>
                                <img src={logo} className="header-logo" />
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/schedule/"} className="nav-link">
                                    Schedule
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/fields/"} className="nav-link">
                                    Fields
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/rules/"} className="nav-link">
                                    Rules
                                </Link>
                            </li>
                            <li>
                                <Link to={"/"+this.props.league.sitename+"/teams/"} className="nav-link">
                                    Teams
                                </Link>
                            </li>
                        </ul>
                    </Toolbar>
                </AppBar>
                <Drawer open={this.state.menuOpen} onClose={this.toggleDrawer}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer}
                        onKeyDown={this.toggleDrawer}
                    >
                        {this.renderDrawer()}
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        league: state.league.info
    };
};

export default connect(mapStateToProps)(Header);