import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import { getLeague } from '../actions/league';
import { getPrettyTime, printMonthShort, getFormattedGameTime } from '../utils/dateUtil';
import { scheduleDisplayUpdate } from '../actions/scheduleDisplay';
import { roundDisplayUpdate } from '../actions/roundDisplay';
import defaultImage from '../assets/images/field-default.png';

class SchedulePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayRound: 0,
        }
    }

    componentDidMount() {
        if (!this.props.isLoaded) {
            this.props.dispatch(getLeague(this.props.match.params.id));
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.month && this.props.match.params.month.match(/^\d+$/) && (parseInt(this.props.match.params.month) - 1) != this.state.monthDisplay.getMonth()) {
            
            const newDate = this.state.monthDisplay;
            newDate.setMonth(parseInt(this.props.match.params.month) - 1); 

            this.setState(() => ({
                monthDisplay: newDate
            }))
            
        }
    }

    updateScheduleType(theType) {
        this.props.dispatch(scheduleDisplayUpdate(theType));
    }

    printTeamName(team_id) {

        let teamName = "";

        this.props.teams.forEach((theTeam) => {
            if (theTeam.id == team_id) {
                teamName = theTeam.name;
            }
        });

        return teamName;
    }
    
    printScore(theGame, isHome) {

        if (theGame.is_played) {
            if (isHome) {
                return theGame.home_score;
            }
            else {
                return theGame.away_score;
            }
        }
        else {
            return '';
        }
    }

    printGameDate(gameTime) {
        const theGameTime = new Date(gameTime);
        return getPrettyTime(theGameTime, true, false);
    }

    printGameTime(gameTime) {
        const theGameTime = new Date(gameTime);
        return getFormattedGameTime(theGameTime);
    }

    printField(field_id) {

        let fieldName = "";

        this.props.fields.forEach((theField) => {
            if (theField.id == field_id) {
                fieldName = theField.name;
            }
        });

        return fieldName;
    }

    renderPlayoffRound() {
        if (this.props.currentRound[0] && this.props.currentRound[0].type == "round_robin") {
            return this.props.currentRound[0].divisions.map((theDivision) => {
                return (
                    <Grid key={theDivision.id} item xs={12} className="grid-container-nopad-vert overflow-horz">

                        <Typography variant="h5" gutterBottom>
                            {theDivision.name}
                        </Typography>

                        <Table className="standings-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Team</TableCell>
                                    <TableCell align="center">Games Played</TableCell>
                                    <TableCell align="center">Points</TableCell>
                                    <TableCell align="center">W</TableCell>
                                    <TableCell align="center">L</TableCell>
                                    <TableCell align="center">T</TableCell>
                                    <TableCell align="center">RF</TableCell>
                                    <TableCell align="center">RA</TableCell>
                                    <TableCell align="center">Forfeits</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {theDivision.standings.map((theStandingsTeam, index) => {
                                return (
                                    <TableRow key={theStandingsTeam.team_id}>
                                        <TableCell align="center">{this.printTeamName(theStandingsTeam.team_id)}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.games_played}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.points}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.wins}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.losses}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.ties}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.runs_for}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.runs_against}</TableCell>
                                        <TableCell align="center">{theStandingsTeam.forfeits}</TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </Grid>
                );
            });
        }
        else if (this.props.currentRound[0] && this.props.currentRound[0].type == "playoff_round") {
            return this.props.currentRound[0].matchups.map((theMatchup) => {
                return (
                    <Grid item xs={12} sm={4} md={4} className="grid-container-nopad-vert overflow-horz" key={theMatchup.id}>

                        <Card className="card">
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {theMatchup.name}
                                    </Typography>

                                    <Grid container>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography>{theMatchup.away_wins}</Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Typography>{this.printTeamName(theMatchup.away_team_id)}</Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Typography align={'center'}>@</Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Typography>{this.printTeamName(theMatchup.home_team_id)}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1}>
                                            <Typography align={'right'}>{theMatchup.home_wins}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            });
        }
        else {
            return (
                <Grid item xs={12} className="grid-container">
                    <Typography variant="h6" gutterBottom>
                        No playoff information has been entered yet.
                    </Typography>
                </Grid>
            );
        }
    }

    updatePlayoffRoundView(round_id) {
        this.props.dispatch(roundDisplayUpdate(round_id));
    }

    render() {
        if (this.props.currentRound) {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h4" className="league-title" gutterBottom>
                                {this.props.info.name}
                            </Typography>
                            <Typography variant="h5" className="page-title" gutterBottom>
                                Playoffs
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="grid-container-pad-bottom">
                            {this.props.rounds.map((theRound) => (
                                <Button key={theRound.id} size="small" className={(this.state.displayRound == theRound.id) ? "button-schedule-left button-schedule-selected" : "button-schedule-left"} color="primary" onClick={() => { this.updatePlayoffRoundView(theRound.id) }}>
                                    {theRound.name}
                                </Button>
                            ))}
                        </Grid>
    
                        {this.renderPlayoffRound()}
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <Grid container>
                        <Grid item xs={12} className="grid-container">
                            <Typography variant="h3" gutterBottom>
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }
};

const mapStateToProps = (state, props) => {
    return {
        isLoaded: state.league.isLoaded,
        info: state.league.info,
        rounds: state.league.playoffRounds,
        currentRound: state.league.playoffRounds.filter((theRound, idx) => {

            // no round displayed, we'll use the first one
            if (state.roundDisplay.round_id == 0 && idx == 0) {
                return true;
            }
            else if (state.roundDisplay.round_id == theRound.id) {
                return true;
            }

            return false;
        }),
        fields: state.league.fields,
        teams: state.league.teams,
        displayRound: state.roundDisplay.round_id
    };
};

export default connect(mapStateToProps)(SchedulePage);